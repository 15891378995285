import React from "react";
import img1 from "../MPC_Images/IMG-20240529-WA0056.jpg";
import img2 from "../MPC_Images/IMG-20240529-WA0049.jpg";
import img3 from "../MPC_Images/IMG-20240529-WA0061.jpg";
import img4 from "../MPC_Images/IMG-20240529-WA0059.jpg";
import img5 from "../MPC_Images/IMG-20240529-WA0054.jpg";
import img6 from "../MPC_Images/IMG-20240529-WA0060.jpg";


const Services = () => {
  const services = [
    {
      id: 1,
      title: "Event Planning and Management",
      description:
        "We ensure that all elements necessary for a successful event are present, making each event a hit, regardless of its type \n\nThis ranges from \n\n • Trade Meets: Typically, this involves trade fairs in Nigeria, we provide an avenue for product education and the demonstration of brand values. MPC ensures the success of your trade events by handling all logistics efficiently \n\n • Conferences/Seminars: We professionally manage the unique challenges posed by AGMs, anniversaries, exhibitions, and trade briefings, ensuring their success",

      image: img1,
    },
    {
      id: 2,
      title: "Digital advertisement",
      description:
        "We take the lead in seamlessly integrating brand experiences across emerging media platforms such as mobile phones and the internet. Leveraging technology, we strive to narrow the communication divide between brands and consumers, fostering deeper engagement and lasting connections.",
      image: img2,
    },
    {
      id: 3,
      title: "Engaging with Schools",
      description:
        "We specialize in engaging with schools through impactful initiatives like the Cowbell Father Xmas Schools Activations and the Indomie Euro-Disney Trip Promotion. From inception to execution, we handle the planning, logistics, and implementation of these successful projects, fostering lasting connections with our clients and their communities",
      image: img3,
    },
    {
      id: 4,
      title: "Brand launching",
      description:
        "At MPC, we don't just launch your products; we curate the entire brand experience. From setting the perfect ambiance to crafting compelling product displays and securing endorsements, we ensure that every aspect of your brand image aligns seamlessly with your objectives. Our goal is to deliver tangible brand equity and leave a lasting impression on your audience",
      image: img4,
    },
    {
      id: 5,
      title: "Road Shows",
      description:
        "MPC's Road Shows are more than just events; they're immersive brand experiences designed to captivate and engage. Led by skilled hosts fluent in local languages, these activations foster meaningful interactions with the audience, guiding them from product education to trial purchase. With visually compelling presentations, our road shows leave a lasting impact and drive consumer engagement",
      image: img5,
    },
    {
      id: 6,
      title: "Product sampling",
      description:
        "Product sampling is the key to immediate recognition. At MPC, we thrive on big challenges - whether it's distributing 20,000 pounds of popcorn across Lagos in a single day or delivering a million mint packages in Maiduguri within a week. No task is too monumental for us. With meticulous planning and execution, we ensure that your product sampling campaigns make a lasting impression and reach their intended audience effectively",
      image: img6,
    },
  ];
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div
          className="my-4 py-4"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2
            className="my-2 text-center text-3xl font-bold"
            style={styles.services}
          >
            Services
          </h2>

          <h2
            className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold"
            style={{  textAlign: "center", width: "80%" }}
          >
            We go beyond traditional media to engage consumers directly through
            lifestyle experiences that resonate with them
          </h2>
        </div>

        <div className="px-12 mt-10" data-aos="fade-up" data-aos-delay="300">
          <div className="grid sm:grid-cols-2 lg:grid-cols-1 gap-10">
            {services.map((service) => (
              <div
                key={service.id}
                className="bg-white rounded-lg shadow-xl overflow-hidden flex flex-col-reverse lg:flex-row"
              >
                <div className="p-6 flex flex-col justify-center">
                  <h2 className="flex items-center font-bold text-2xl sm:text-3xl mb-4">
                    <span className="mr-2">{service.icon}</span>
                    {service.title}
                  </h2>
                  <p
                    className="text-md sm:text-lg"
                    style={{ whiteSpace: "pre-line", fontSize: "17px"}}
                  >
                    {service.description}
                  </p>
                </div>
                <img
                  alt="card img"
                  className="w-full lg:w-2/5 h-auto"
                  src={service.image}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
            data-aos="zoom-out"
          >
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path>
                  <path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path>
                </svg>
              </div>

              <h3 className="text-3xl  text-blue-900 font-bold">
                We <span className="font-black">Promote</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We will promote your brand in ways that captivate and engage
                  your target audience
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
              <div className="text-blue-900 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="72"
                  height="72"
                  viewBox="0 0 24 24"
                  className="fill-current"
                >
                  <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                </svg>
              </div>

              <h3
                className="text-3xl  text-blue-900 font-bold"
              >
                We <span className="font-black">Market</span>
              </h3>
              <div>
                <p className="my-3 text-xl text-gray-600 font-semibold">
                  We also excel in marketing your brand with innovative
                  strategies and effective campaigns that deliver results
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const styles = {
  services: {
    fontFamily: "Karla, sans-serif",
    textShadow: "2px 4px 8px rgba(0, 0, 0, 0.3)",
    fontVariant: "small-caps",
    color: "#3681D1",
  },
};
export default Services;
