import React, { useState, useEffect } from "react";
import NavLinks from "../Navbar/NavLinks";
import { HashLink } from "react-router-hash-link";

const NavBar = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function handleClick() {
    setisOpen(!isOpen);
  }

  return (
    <nav
      className={`fixed top-0 w-full z-30 transition duration-300 ease-in-out mb-16`}
      style={{ backgroundColor: "white" }}
    >
      <div
        className="flex flex-row justify-between items-center py-2"
        style={styles.navWrapper}
      >
        <div className="flex flex-row justify-center md:px-12 md:mx-12 items-center text-center font-semibold">
          <HashLink smooth to="/#home">
            <h1 className="font-extrabold text-4xl" style={styles.companyName}>
              MPC International
            </h1>
          </HashLink>
        </div>
        <div className="group flex flex-col items-center">
          <button
            className="p-2 rounded-lg lg:hidden text-blue-900"
            onClick={handleClick}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen && (
                <path
                  fillRule="evenodd"
                  color="white"
                  clipRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              )}
              {!isOpen && (
                <path
                  fillRule="evenodd"
                  color="white"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
          <div className="hidden space-x-6 lg:inline-block p-5">
            <NavLinks />
          </div>

          <div
            className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full  rounded-md p-24 lg:hidden shadow-xl ${
              isOpen ? "block" : "hidden"
            } `}
            style={{
              backgroundColor: "#3681D1",
              marginTop: "48px",
              height: "50%",
            }}
          >
            <div className="flex flex-col space-y-6">
              <NavLinks />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const styles = {
  navWrapper: {
    backgroundColor: "#3681D1",
    boxShadow: "1px 1px 4px gray",
    padding: "0px",
  },
  companyName: {
    color: "white",
    textShadow: "2px 4px 8px rgba(0, 0, 0, 1)",
    fontVariant: "small-caps",
    fontWeight: "bold",
    fontSize: "2rem",
    letterSpacing: "1.5px",
    margin: "20px 0",
    marginLeft: "10px",
  },
};

export default NavBar;
